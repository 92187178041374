import request from "@/api/config.js";

/* export function update(credentials) {
    return request({
        url: `user/update/${credentials.id}`,
        method: 'post',
        data: credentials
    }).then(response => response.data)
} */
export function getStudentsReviewDateForArray(data) {
  return request({
    url: `user/get-students-review-date/for-array`,
    method: "post",
    data,
  });
}

export function update(id, data) {
  return request({
    url: `user/update/${id}`,
    method: "post",
    data,
  });
}

export function findById(id) {
  return request({
    timeout: 60000,
    url: `user/get/${id}`,
    method: "get",
    //data
  });
}
export function getPinnedTask(id) {
  return request({
    url: `user/get/pinned-task/by/${id}`,
    method: "get",
    //data
  });
}
export function getUserCells(id) {
  return request({
    url: `user/get/user-cells/by/${id}`,
    method: "get",
    //data
  });
}

export function findByEmail(data) {
  return request({
    url: `user/get/by/email`,
    method: "post",
    data,
  });
}

export function upload(id) {
  return request({
    url: `user/upload/${id}`,
    method: "post",
    data,
  });
}

export function getRegionAndUniver(id) {
  return request({
    url: `user/getRegionAndUniver/${id}`,
    method: "post",
  });
}

export function getUserRegionAndUniver(id) {
  return request({
    url: `user/getRegionAndUniver/${id}`,
    method: "post",
  });
}

export function getRegionAndUniverList() {
  return request({
    url: `user/getRegionAndUniverList`,
    method: "post",
  });
}

export function getRegionByID(id) {
  return request({
    url: `user/getRegionByID/${id}`,
    method: "post",
  });
}

export function getUniverByID(id) {
  return request({
    url: `user/getUniverByID/${id}`,
    method: "post",
  });
}

export function getStudentsStatusForArray(data) {
  return request({
    url: `user/get-students-status/for-array`,
    method: "post",
    data,
  });
}
export function changeStudentStatusByID(data) {
  return request({
    url: `user/set-students-status/by-id`,
    method: "post",
    data,
  });
}

export function getUserInfoForRating(id) {
  return request({
    url: `user/get/user/${id}/info-for-rating`,
    method: "get",
  });
}
