<template>
  <div id="app">
    <router-view />
    <ButtonTelegram />
  </div>
</template>

<script>
import ButtonTelegram from "@/components/Landing/ButtonTelegram";
export default {
  components: {
    ButtonTelegram,
  },
};
</script>

<style>
html body {
  background-color: #000;
}
</style>

<style scoped>
@import "./assets/css/Repainting/Dialogs.css";
@import "./assets/css/Repainting/newStyle.css";
@import "./assets/css/Repainting/Buttons.css";
@import "./assets/css/Repainting/Organization.css";
@import "./assets/css/Repainting/Scroll.css";
@import "./assets/css/Repainting/Checkboxes.css";
@import "./assets/css/Repainting/FiltersDropDown.css";
@import "./assets/css/Repainting/Adaptation.css";
@import "./assets/css/Repainting/VQuillTheme.css";
@import "./assets/css/Repainting/Modal.css";
@import "./assets/css/Repainting/StyleVars.css";
@import "./assets/css/Repainting/VueCall.css";
</style>
